import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat, mobileNumber } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, data, vm, search, searchResult, getColumnName) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
        pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [
          {
            columns: reportHeadData.reportHeadColumn, style: 'main_head'
          },
          { text: reportHeadData.address, style: 'address', alignment: 'center' }
        ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRowsHead = [
          [
            { text: vm.$t('org_pro_division.division') + ' : ' + (search.division_id ? (i18n.locale === 'bn' ? searchResult.division_name_bn : searchResult.division_name) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('org_pro_district.district') + ' : ' + (search.district_id ? (i18n.locale === 'bn' ? searchResult.district_name_bn : searchResult.district_name) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('org_pro_upazilla.upazilla') + ' : ' + (search.upazilla_id ? (i18n.locale === 'bn' ? searchResult.upazilla_name_bn : searchResult.upazilla_name) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('dae_subsidy.fiscal_year') + ' : ' + (search.fiscal_year_id ? (i18n.locale === 'bn' ? searchResult.fiscal_year_bn : searchResult.fiscal_year) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        const allRowsHead1 = [
          [
            { text: vm.$t('farm_config.season') + ' : ' + (search.season_id ? (i18n.locale === 'bn' ? searchResult.season_bn : searchResult.season) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('farm_config.circular') + ' : ' + (search.circular_id ? (i18n.locale === 'bn' ? searchResult.circular_name_bn : searchResult.circular_name) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('farm_config.machine_type') + ' : ' + (search.machine_type_id ? (i18n.locale === 'bn' ? searchResult.machine_type_bn : searchResult.machine_type) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' },
            { text: vm.$t('farm_config.instrument_name') + ' : ' + (search.instrument_name_id ? (i18n.locale === 'bn' ? searchResult.instrument_name_bn : searchResult.instrument_name) : vm.$t('globalTrans.all')), alignment: 'left', style: 'th' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: '*',
            body: allRowsHead1
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.district'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.upazila'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.union'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('dae_grant_allocation_distribution.farmer_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('dae_grant_allocation_distribution.farmer_mobile_number'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_tim.name_of_father_husband'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.address'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('dae_grant_allocation_distribution.farmer_nid'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('farm_report.bill_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('farm_report.supplier_company_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('dae_subsidy.model'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('dae_subsidy.brand_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('farm_config.instrument_name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('farm_report.engine_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('farm_report.chassis_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$n(50, { useGrouping: false }) + '%', style: 'th', alignment: 'center', bold: true },
            { text: vm.$n(70, { useGrouping: false }) + '%', style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.total'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('farm_report.date_of_supply_of_equipment_to_the_farmer'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        if (data.length > 0) {
          data.forEach((district, optionIndex) => {
            district.upazilla.forEach((upazilla, upazilaIndex) => {
              upazilla.list.forEach((info, infoIndex) => {
                const NewLoop = []
                NewLoop.push({ text: vm.$n(info.farmer_serial, { useGrouping: false }), style: 'td', alignment: 'center' })
                if (upazilaIndex === 0 && infoIndex === 0) {
                  NewLoop.push({ text: getColumnName(vm.$store.state.commonObj.districtList, district.district_id), style: 'td', rowSpan: district.rowSpan, alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                if (infoIndex === 0) {
                  NewLoop.push({ text: getColumnName(vm.$store.state.commonObj.upazilaList, info.upazilla_id), style: 'td', rowSpan: upazilla.list.length, alignment: 'center' })
                } else {
                  NewLoop.push({})
                }
                NewLoop.push({ text: getColumnName(vm.$store.state.commonObj.unionList, info.union_id), alignment: 'center', style: 'td' })
                NewLoop.push({ text: (i18n.locale === 'bn' ? info.name_bn : info.name), alignment: 'center', style: 'td' })
                NewLoop.push({ text: vm.checkLanguage(info.mobile_no) === 'english' ? mobileNumber(info.mobile_no) : info.mobile_no, alignment: 'center', style: 'td' })
                NewLoop.push({ text: (i18n.locale === 'bn' ? info.father_name_bn : info.father_name), alignment: 'center', style: 'td' })
                NewLoop.push({ text: (i18n.locale === 'bn' ? info.address_bn : info.address), alignment: 'center', style: 'td' })
                NewLoop.push({ text: vm.$n(info.nid_no, { useGrouping: false }), alignment: 'center', style: 'td' })
                NewLoop.push({ text: info.bill_no, alignment: 'center', style: 'td' })
                NewLoop.push({ text: (i18n.locale === 'bn' ? info.supplier_name_bn : info.supplier_name), alignment: 'center', style: 'td' })
                NewLoop.push({ text: info.spp_model, alignment: 'center', style: 'td' })
                NewLoop.push({ text: getColumnName(vm.$store.state.incentiveGrant.commonObj.macBrandList, info.spp_brand_id), alignment: 'center', style: 'td' })
                NewLoop.push({ text: vm.getInstrumentName(info.instrument_id), alignment: 'center', style: 'td' })
                NewLoop.push({ text: info.engine_no, alignment: 'center', style: 'td' })
                NewLoop.push({ text: info.chasis_no, alignment: 'center', style: 'td' })
                if (!info.haor_costal_area) {
                  NewLoop.push({ text: vm.$n(info.farmer_value), alignment: 'center', style: 'td' })
                } else {
                  NewLoop.push({ text: '', alignment: 'center', style: 'td' })
                }
                if (info.haor_costal_area) {
                  NewLoop.push({ text: vm.$n(info.farmer_value), alignment: 'center', style: 'td' })
                } else {
                  NewLoop.push({ text: '', alignment: 'center', style: 'td' })
                }
                NewLoop.push({ text: vm.$n(info.farmer_value), alignment: 'center', style: 'td' })
                NewLoop.push({ text: info.distributed_date ? dateFormat(info.distributed_date) : info.excel_date, alignment: 'center', style: 'td' })
                allRows.push(NewLoop)
              })
            })
          })
          allRows.push(
          )
          } else {
            allRows.push(
              [
                { text: vm.$t('globalTrans.noDataFound'), colSpan: '20', style: 'td', alignment: 'center' },
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {},
                {}
              ]
          )
        }
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['4%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '4%', '7%', '4%', '5%', '5%', '5%', '5%', '5%', '5%', '5%', '6%'],
              body: allRows
            }
          })
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A2',
        pageOrientation: 'Landcape',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n.locale === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          search: {
            fontSize: (i18n.locale === 'bn') ? 10 : 8,
            margin: [3, 3, 3, 3]
          },
          fertilizer: {
            margin: [5, 0, 0, 5]
          },
          header: {
            fontSize: 12,
            margin: [0, 0, 0, 4]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 5]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 0]
          },
          address: {
            fontSize: 9,
            margin: [0, -10, 0, 0]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
